.box {
    position: relative;
    background: #fff;
    box-shadow: 0 0 15px rgba(0,0,0,.1);
  }
  
  /* common */
  .ribbon {
    width: 110px;
    height: 110px;
    overflow: hidden;
    position: absolute;
  }
  .ribbon::before,
  .ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #2980b9;
  }
  .ribbon span {
   
    position: absolute;
    display: block;
    width: 200px;
    padding: 5px 0;
    background-color: #18ae6b;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font: 700 18px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    /* text-transform: uppercase; */
    text-align: center;
  }
  
  /* top left*/
  .ribbon-top-left {
    top: -6.5px;
    left: -6.5px;
  }
  .ribbon-top-left::before,
  .ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-top-left::before {
    top: 0px;
    right: 0px;
  }
  .ribbon-top-left::after {
    bottom: 0px;
    left: 0px;
  }
  .ribbon-top-left span {
    right: -38px;
    top: 15px;
    transform: rotate(-45deg);
  }
  
  /* top right*/
  .ribbon-top-right {
    top: -10px;
    right: -10px;
  }
  .ribbon-top-right::before,
  .ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent;
  }
  .ribbon-top-right::before {
    top: 0;
    left: 0;
  }
  .ribbon-top-right::after {
    bottom: 0;
    right: 0;
  }
  .ribbon-top-right span {
    left: -25px;
    top: 30px;
    transform: rotate(45deg);
  }

  .title{
    font-size: 16px;
    font-weight: 400;
    font-family: "poppins";
  }

.title1{
    font-size: 18px;
    font-weight: 500;
    font-family: "poppins";
}
.title2{
    font-size: 13px;
    font-weight: 500;
    font-family: "poppins";
}
.heading1{
    font-size: 22px;
    font-weight: 600;
    font-family: "poppins";
    display: block
}
.heading2{
    font-size: 18px;
    font-weight: 500;
    font-family: "poppins";
    display: block
}
.btn-text{
    font-size: 13px;
    font-weight: 600;
    font-family: "poppins";
}
.lable-text{
    font-size: 11px;
    font-weight: 600;
    font-family: "poppins";
}


  
  