.inpstyle{
  padding: 10px 15px 10px 15px;
  font-size: 14px;
}
.box{
  text-align: center;
}

.separator{
  display:flex;
  align-items: center;
}

.separator .line{
  height: 2px;
  flex: 1;
  background-color: #ccc;
}

.separator h4{
  padding: 0rem 1rem;
  color:"#bbb"
}